import React from "react";
import legalDocumentStyles from "../LegalDocuments.module.css";
import Wrapper from "../../UI/Wrapper/Wrapper";

const TermsOfService = () => (
    <Wrapper>
        <div className={legalDocumentStyles.LegalDocuments}>
            <h1>OPŠTI USLOVI KORIŠĆENJA
                PLATFORME APOTEKA.RS</h1>
            <div>
                <ol type="I">
                    <li className={legalDocumentStyles.Headers}>KO SMO MI</li>
                    <ol type="1">
                        <li>
                            <p>Mi smo Extra Care Group d.o.o. Beograd – Zemun, privredno društvo registrovano u Agenciji za privredne registre sa sedištem na
                            adresi Dobanovačka 2, matični broj: 20990376, PIB: 108392380 (u daljem tekstu: Extra Care Group ili Mi).</p>
                        </li>
                        <li>
                            <p>Extra Care Group je osmislio, razvio i dizajnirao Platformu Apoteka.rs (web i mobilne aplikacije za iOS i Android).</p></li>
                        <li>
                            <p>Apoteka.rs je informativni servis. Partneri pružaju usluge iz delatnosti za koju su registrovani, u skladu sa zakonom i regulativom svoje delatnosti. Apoteka.rs omogućava komunikaciju i povezivanje korisnika sa ovlašćenim pružaocima usluga.</p></li>
                        <li>
                            <p>Naša platforma ni na koji način nije povezana, sponzorisana ili pod nadzorom kompanija Apple Inc i Google LLC.</p></li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>PREDMET OPŠTIH USLOVA</li>
                    <ol type="1">
                        <li>
                            <p>Ovim Opštim uslovima korišćenja Platforme Apoteka.rs (u daljem tekstu: Opšti uslovi), utvrđuju se uslovi pristupa i korišćenja
                                softvera Platforme Apoteka.rs, koji se primenjuje na našu Android i iOS aplikaciju Apoteka.rs i web aplikaciju
                                https://apoteka.rs, kao i bilo kog drugog servisa ili proizvoda Platforme, uključujući i korisničke naloge, korisničku i tehničku podršku, blogove i
                                servise društvenih mreža i dr.</p>
                        </li>
                        <li>
                            <p>Ovi Opšti uslovi se primenjuju kako na apoteke i druga pravna ili fizička lica koja upotrebljavaju Platformu i njene
                                funkcionalnosti u obavljanju svoje delatnosti, tako i na lica koja koriste našu Platformu.</p>
                        </li>
                        <li>
                            <p>Ovi Opšti uslovi se primenjuju na naše Partnere od momenta zaključenja posebnog ugovora sa nama, odnosno na Korisnike od trenutka
                                njihovog prihvatanja, što je neophodan korak u cilju preuzimanja, instalacije ili korišćenja bilo kog od dela Platforme.</p>
                        </li>
                        <li>
                            <p>Korišćenjem naše Platforme, pristajete na ove Opšte uslove, stoga molimo da ih pažljivo pročitate pre nego što započnete sa
                                korišćenjem Platforme.
                            </p>
                        </li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>DEFINICIJE</li>
                    <ol type="1">
                        <li>
                            <p>Za potrebe ovih Opštih uslova korišćenja, izrazi koji se koriste u daljem tekstu imaju sledeće značenje:</p>
                            <ul>
                                <li>
                                    <p><strong>"Platforma"</strong> predstavlja zajednički naziv za našu Android i iOS aplikaciju Apoteka.rs i web aplikaciju https://apoteka.rs, kao i bilo kog drugog servisa ili proizvoda Platforme, uključujući i korisničke naloge, korisničku i tehničku podršku, blogove i servise društvenih mreža i slično;</p>
                                </li>
                                <li>
                                    <p><strong>„Usluge"</strong> jesu sve funkcionalnosti koje Platforma nudi i koje koriste naši Partneri i/ili Korisnici;</p>
                                </li>
                                <li>
                                    <p><strong>"Partneri"</strong> jesu apoteke i druga pravna ili fizička lica koja upotrebljavaju Platformu i njene funkcionalnosti u obavljanju svoje delatnosti ili koja na bilo koji način podržavaju rad Platforme, uključujući i Udruženje, a kako je dole detaljnije objašnjeno;</p>
                                </li>
                                <li>
                                    <p><strong>„Proizvodi"</strong> jesu kozmetički proizvodi, dijetetski suplementi, vitamini i minerali, hrana za posebne namene, lekovi i drugi proizvodi iz asortimana naših Partnera;</p>
                                </li>
                                <li>
                                    <p><strong>„Korisnici"</strong> jesu lica koja su se registrovala i koriste Usluge naše Platformu i njene funkcionalnosti;</p>
                                </li>
                                <li>
                                    <p><strong>"Udruženje"</strong> jeste Udruženje "ZDRAVO SVIMA" sa sedištem na adresi Gramšijeva 2, Beograd-Novi Beograd, pod matičnim brojem: 28375310, osnovano radi ostvarivanja ciljeva u oblasti inovativne pomoći u zdravstvu i digitalizacije u zdravstvu i jedan od naših Partnera sa kojim imamo zaključen ugovor i koje koristi Platformu radi ostvarivanja ciljeva Udruženja i pružanja benefita i pomoći svojim članovima. Za sve što je ovim Uslovima drugačije definisano za Udruženje u odnosu na ostale Partnere Platforme, primenjivaće se odredbe koje se odnose na Udruženje. U suprotnom, primenjivaće se odredbe ovim Uslova koje se odnose na naše Partnere;</p>
                                </li>
                                <li>
                                    <p><strong>"članovi Udruženja"</strong> jesu lica koja su prihvatila Statut Udruženja i pristupila Udruženju. Za sve što je ovim Uslovima posebno regulisano za članove Udruženja, primenjivaće se te odredbe. U suprotnom, primenjivaće se odredbe ovim Uslova koje se odnose na naše Korisnike.</p>
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>FUNKCIONALNOSTI PLATFORME</li>
                    <ol type="1">
                        <li>
                            <p>Naša Platforma omogućava Partneru:</p>
                        </li>
                        <ul>
                            <li>
                                <p>Da istakne proizvode iz svog asortimana putem Platforme;</p>
                            </li>
                            <li>
                                <p>Da komunicira sa našim Korisnicima putem Platforme;</p>
                            </li>
                            <li>
                                <p>Da prihvata rezervacije ili vrši prodaju proizvoda iz svog asortimana, za proizvode kod kojih je to dozvoljeno pozitivnim
                                    propisima, putem Platforme;</p>
                            </li>
                            <li>
                                <p>Druge funkcionalnosti obuhvaćene posebnim ugovorom.</p>
                            </li>
                        </ul>
                        <li>
                            Naša Platforma omogućava Korisniku:
                        </li>
                        <ul>
                            <li>
                                <p>da izvrši proveru dostupnosti određenog proizvoda kod naših Partnera;</p>
                            </li>
                            <li>
                                <p>da pronađe najbližeg Partnera kod kojeg postoji dostupan proizvod u odnosu na svoju lokaciju;</p>
                            </li>
                            <li>
                                <p>da pošalje upit Partneru u vezi sa proizvodom iz njegovog asortimana;</p>
                            </li>
                            <li>
                                <p>da izvrši izbor kod kog našeg Partnera želi da pribavi željeni proizvod u kojima se isti može nabaviti u odnosu na svoju
                                lokaciju;</p>
                            </li>
                            <li><p>da izvrši njegovu rezervaciju kod odabranog Partnera;</p></li>
                            <li>
                                <p>da izvrši on-line naručivanje i kupovinu proizvoda iz asortimana Partnera, za proizvode kod kojih je to dozvoljeno pozitivnim
                                propisima;</p>
                            </li>
                            <li>
                                <p>da u svakom momentu zatraži stručnu pomoć diplomiranog farmaceuta koji mu može nepristrasno, objektivno i stručno pomoći u
                                izboru leka ili medicinskog sredstva, ukoliko je to željeni proizvod Korisnika.</p>
                            </li>
                        </ul>
                        <li>
                            <p>Naša Platforma omogućava Udruženju:</p>
                        </li>
                        <ul>
                            <li>
                                <p>da izvrši jednostavno međusobno povezivanje između svojih članova, kao i članova Udruženja sa našim drugim Partnerima i diplomiranim farmaceutima;</p>
                            </li>
                            <li>
                                <p>slanje obaveštenja članovima o novosti koje se tiču rada i ciljeva Udruženja, ali i o našim Partnerima i proizvodima;</p>
                            </li>
                            <li>
                                <p>bezbedno skladištenje dokumentacije koja se odnosi na rad Udruženja;</p>
                            </li>
                            <li>
                                <p>da njegovi članovi Udruženja mogu da koriste sve Usluge Platforme kao i drugi naši Korisnici;</p>
                            </li>
                            <li>
                                <p>druge benefite za članove Udruženja koji budu obuhvaćeni posebnim ugovorom između nas i Udruženja.</p>
                            </li>
                        </ul>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>ULOGA PLATFORME</li>
                    <ol type="1">
                        <li>
                            <p>Platforma je informativni servis i omogućuje Partnerima da na njoj istaknu svoje proizvode, a Korisnicima da na njoj mogu da pretražuju, rezervišu i
                                kupuju proizvode iz asortimana naših Partnera.</p>
                        </li>
                        <li>
                            <p>Stoga, kada Kupac vrši rezervaciju ili kupuje proizvod od odabranog Partnera, mi nismo strana u ugovoru između Korisnika i
                                Partnera (u daljem tekstu: Ugovor o kupoprodaji), iz kog razloga kada Korisnik vrši rezervaciju ili kupuje preko naše Platforme
                                proizvode koji se mogu kupiti on-line, mi obezbeđujemo i odgovorni smo za funkcionisanje same Platforme, ali ne i za samu
                                kupoprodaju.</p>
                        </li>
                        <li>
                            <p>Dodatno, Platforma omogućava kako povezivanje između članova Udruženja, kao i članova Udruženja sa našim drugim Partnerima, tako i druge benefite za članove za koje nas Udruženje angažuje u cilju ostvarivanja svojih ciljeva, a posebno radi zaštite i unapređenja zdravlja svojih članova. Radi izbegavanja svake sumnje, Platforma isključivo omogućava Udruženju i njegovim članovima softversko rešenje kao kanal komunikacije i sredstvo za ostvarivanje ciljeva. Međutim, radi izbegavanja svake sumnje, kao i u slučaju Ugovora o kupoprodaji, Platforma nije ugovorna strana i ne može biti odgovorna za postupanje i ponašanje članova Udruženja i ispunjenje usluga naših Partnera, već isključivo odgovara za uredno funkcionisanje Platforme.</p>
                        </li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>TAČNOST I AŽURNOST PODATAKA</li>
                    <ol type="1">
                        <li>
                            <p>Preduzimamo odgovarajuće mere predostrožnosti prilikom omogućavanja korišćenja naše Platforme, ali ne možemo da garantujemo da su
                                sve informacije koje se na njoj nalaze, odnosno koje su dostupne Korisnicima, tačne i ažurne jer iste dobijamo od Partnera. </p>
                        </li>
                        <li>
                            <p>Kako se informacije o proizvodima zasnivaju na podacima koje su Partneri dužni da ažurno dostavljaju Platformi, Partneri su ti
                                koji su odgovorni za to da podaci i informacije koje se tiču njihovih proizvoda budu tačne i ažurne.</p>
                        </li>
                        <li>
                            <p>Do netačnosti, odnosno neažurnosti prikazanih informacija može doći i zbog sinhronizacije podataka između Partnera i Platforme za
                                koju je potrebno u proseku od 3 do 5 minuta u kom periodu može doći do razmimoilaženja između prikazanih i stvarnih
                                informacija.</p>
                        </li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>USLOVI KORIŠĆENJA ZA PARTNERE</li>
                    <ol type="1">
                        <li>
                            <p>Partner je dužan da na Platformi istakne sve proizvode koje ima u svom asortimanu.</p>
                        </li>
                        <li>
                            <p>Partner se obavezuje da u toku korišćenja Platforme redovno ažurira podatke o asortimanu svojih proizvoda, kako bi Korisnicima u
                                svakom momentu bili dostupni ažurni i tačni podaci o dostupnosti proizvoda na Platformi koji su prikazani u asortimanu
                                Partnera.</p>
                        </li>
                        <li>
                            <p>Partner se obavezuje da svaki upit, rezervaciju ili kupovinu Korisnika Platforme obradi i realizuje u što kraćem vremenskom
                                periodu, ali ne dužem od 5 minuta od slanja konkretnog upita Korisnika Platforme ukoliko je upit poslat u radno vreme Partnera.
                                Ukoliko je upit poslat nakon radnog vremena Partnera, rok od 5 minuta počinje da teče od početka radnog vremena Partnera
                                narednog radnog dana.</p>
                        </li>
                        <li>
                            <p>Partner koji koristi usluge Plarforme odgovara za tačnost i ažurnost svih podataka koji se tiču njegovih proizvoda i isključivo
                                odgovara za štetu koja može nastati Korisniku u vezi sa njegovim proizvodima koji se objavljuju na Platformi. </p>
                        </li>
                        <li>
                            <p>Partner se obavezuje da nas bez odlaganja, a najkasnije u roku od 24 časa obavesti o bilo kakvog problemu ili nedostatku u radu
                                Platforme koje uoči prilikom korišćenja Platforme.</p>
                        </li>
                        <li>
                            <p>Prava i obaveze Extra Care Group i Partnera u vezi uslova korišćenja Platforme i njenih funkcionalnosti su detaljno
                                regulisane u posebnom ugovoru koji je važeći i obavezujući za obe ugovorne strane. Za sve što je drugačije regulisano u posebnom
                                ugovoru u odnosu na ove Opšte uslove, primenjivaće se odredbe tog ugovora.
                            </p>
                        </li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>USLOVI KORIŠĆENJA ZA KORISNIKE</li>
                    <ol type="1">
                        <li>
                            <p>Korišćenje naše Platforme je u potpunosti besplatno za Korisnike.</p>
                        </li>
                        <li>
                            <p>Ukoliko želite da pretražujete koji naši Partneri nude svoje proizvode i njihovu raspoloživost, isto možete učiniti preko naše
                                Platforme.</p>
                        </li>
                        <li>
                            <p>Ukoliko želite da vidite cenu proizvoda naših Partnera, pošaljete upit našem Partneru u vezi sa proizvodom, izvršite rezervaciju
                                proizvoda ili isti kupite, morate da se registrujete kao Korisnik naše Platforme.</p>
                        </li>
                        <h4>Registracija korisničkog naloga</h4>
                        <li>
                            <p>Da biste registrovali Vaš korisnički nalog, morate da kreirate nalog na našoj internet stranici https://apoteka.rs unošenjem e-mail i lozinke ili da preuzmete Platformu iz Apple AppStore ili Google Play prodavnice aplikacija. Ukoliko preuzimate Platformu iz Apple AppStore ili Google Play prodavnice, Korisnik izjavljuje da je pročitao opšte uslove korišćenja Apple AppStore ili Google Play prodavnica. Prihvatanjem ovih opštih uslova korišćenja, Korisnik prihvata da Extra Care Group neće imati uticaja na uređivanje i rad Apple AppStore ili Google Play aplikacija i da Extra Care Group neće biti odgovoran u slučaju bilo kakve promene u navedenim propisima ili funkcionisanju kompanija koje su vlasnici Apple AppStore ili Google Play.</p>
                        </li>
                        <li>
                            <p>Kako biste se registrovali i koristili sve funkcionalnosti Platforme, neophodno je da imate minimum 18 godina, da unesete svoj
                                korisnički nalog, odnosno adresu elektronske pošte i šifru. </p>
                        </li>
                        <li>
                            <p>Ukoliko prilikom registracije na Platformi, izaberete korisničko ime ili određenu identifikaciju za svoj Korisnički nalog koja
                                nije adekvatna, možemo da zatražimo da isto izmenite ako smatramo da je neophodno (npr. već je u upotrebi, povređuje nečija
                                prava i slično).</p>
                        </li>
                        <li>
                            <p>Nakon što unesete uredne podatke, poslaćemo Vam e-mejl na adresu elektronske pošte koju ste uneli, a koji je neophodno da primite
                                kako biste završili Vašu registraciju, te stoga ukazujemo da isti može stići u junk, spam ili drugi sličan folder neželjene
                                pošte, te stoga molimo da i tu poštu blagovremeno proveravate u cilju izbegavanja eventualnih nesporazuma.</p>
                        </li>
                        <li>
                            <p>Radi bezbednosti i sigurnosti, kako Vas, tako i naše Platforme, koristimo sistem dvostruke autentifikacije.</p>
                        </li>
                        <li>
                            <p>Nije dozvoljeno dostavljanje netačnih informacija ili lažno predstavljanje, kao ni da pravite nalog u ime druge osobe, osim
                                ukoliko nemate njihovu prethodnu izričitu saglasnost. S obzirom na to da nemamo mogućnost provere, smatraćemo da je Korisnik
                                isto lice koje je dostavilo podatke prilikom registracije i koje koristi našu Platformu.</p>
                        </li>
                        <li>
                            <p>Korisniku kome smo prethodno izbrisali nalog ili blokirali korišćenje Platforme, odnosno koji je prekršio, zloupotrebio neku od
                                obaveza predviđenu ovim Opštim uslovima, odnosno primenljivim zakonima vezanim za svrhu i korišćenje Pratforme i naših servisa,
                                izričito je zabranjeno da ponovo koristi Platformu.</p>
                        </li>
                        <li>
                            <p>Zadržavamo pravo da odbijemo bilo koji Korisnički nalog ako bi isti povređivao ove Opšte uslove ili druga primenljiva pravila
                                Platforme.</p>
                        </li>
                        <li>
                            <p>Korisnik može da otvori, održava, koristi i kontroliše samo jedan nalog i Korisniku je izričito zabranjeno da bude vlasnik ili
                                suvlasnik drugih naloga.</p>
                        </li>
                        <li>
                            <p>Korisnik je dužan da koristi Korisnički nalog, kao i bilo koji deo Platforme u namenske svrhe, odnosno u svrhe koje su određene
                                ovim Opštim uslovima.</p>
                        </li>
                        <li>
                            <p>Prilikom registrovanja Korisničkog naloga, dolazi do obrade Vaših podataka o ličnosti, a za informacije o tome kako obrađujemo
                                Vaše podatke o ličnosti, u koju svrhu, po kom pravnom osnovu i slično, možete pogledati u našoj Izjavi o privatnosti klikom na
                                sledeći link - <a href="https://apoteka.rs/PrivacyPolicy">https://www.apoteka.rs/PrivacyPolicy</a></p>
                        </li>
                        <h4>Bezbednost Platforme i Korisničkog naloga</h4>
                        <li>
                            <p>Platforma, a naročito njene aplikacije, se mogu koristiti samo od strane Korisnika i to samo na autorizovanim uređajima (ukoliko
                                je instalirana na uređajima koji su u legalnom vlasništvu ili posedovanju Korisnika).</p>
                        </li>
                        <li>
                            <p>Dužni ste da vodite računa da informacije na Vašem nalogu budu tačne i ažurirane jer u suprotnom postoji mogućnost da nećete moći
                                da pristupite nalogu, a time i uslugama koje naša Platforma nudi ili da se realizuje zaključeni Ugovor o kupoprodaji.</p>
                        </li>
                        <li>
                            <p>Korisnik snosi odgovornost za bezbednost svog Korisničkog naloga, kao i svog korisničkog imena i lozinke. Extra Care Group ne
                                može i neće biti odgovoran za bilo kakav gubitak ili štetu koji proizilaze iz propusta Korisnika da ispuni ovu obavezu koja se
                                odnosi na bezbednost svog naloga i njegovih podataka.</p>
                        </li>
                        <li>
                            <p>Ukoliko Korisnik izgubi šifru ili primeti da neko neovlašćeno koristi njegov Korisnički nalog, dužan je da nas bez odlaganja o
                                tome obavesti putem adrese elektronske pošte <a href="mailto:podrska@apoteka.rs">podrska@apoteka.rs</a>, kako bi lozinka odmah bila promenjena, a Korisnik zaštićen od
                                dalje zloupotrebe.</p>
                        </li>
                        <h4>Korišćenje Korisničkog naloga</h4>
                        <li>
                            <p>Kada imate registrovan Korisnički nalog, možete da koristite sve funkcionalnosti Platforme, odnosno da pošaljete upit u vezi
                                određenog proizvoda našem Partneru, da izvršite rezervaciju ili kupovinu proizvoda, kao i da kontaktirate označenog farmaceuta
                                radi nejasnoća i konsultacija u vezi sa proizvodom.</p>
                        </li>
                        <li>
                            <p>Kada pošaljete upit, zatražite rezervaciju ili kupovinu određenog proizvoda, Platforma takav upit prosleđuje odabranom Partneru,
                                a naš Parner ima 5 minuta da odgovori na Vaš upit, odnosno da odgovori da li prihvata Vašu rezervaciju/porudžbinu, u kom slučaju
                                je dužan da navede u kom roku možete preuzeti rezervisani proizvod ili već u kom roku Vam se može isti isporučiti. Ukoliko sa
                                druge strane naš Partner nije u mogućnosti da udovolji Vašim zahtevima, dužan je da navede razloge za isto.</p>
                        </li>
                        <li>
                            <p>Nakon što je rezervacija, odnosno porudžbina za kupovinu potvrđena od strane našeg Partnera, više je niti Korisnik niti Partner
                                ne mogu otkazati, stoga molimo da pre slanja rezervacije ili porudžbine proizvoda, odnosno njene potvrde, pažljivo odaberete
                                Partnera i pregledate izbor traženih proizvoda. </p>
                        </li>
                        <li>
                            <p>Kada je potvrđena/odbijena rezervacija Korisnika od strane Partnera, Platforma takvu informaciju odmah prosleđuje Korisniku na
                                način da takva informacija postaje vidljiva na njegovom Korisničkom nalogu, a ista se šalje i na adresu elektronske pošte
                                Korisnika.</p>
                        </li>
                        <li>
                            <p>Ukoliko je Korisnik izvršio rezervaciju proizvoda, dužan je da iste preuzme u roku od 24 časa od momenta potvrde rezervacije od
                                strane našeg Partnera.</p>
                        </li>
                        <li>
                            <p>Ukoliko je Korisnik kupio Proizvod, za proizvode koji se mogu kupiti on-line, tada će poručeni proizvod biti dostavljen na adresu
                                koju je Korisnik naznačio kao adresu dostave putem Platforme.</p>
                        </li>
                        <li>
                            <p>Za ostvarivanje pogodnosti iz našeg Loyalty programa, uključujući i razgovore sa farmaceutom, molimo pročitajte uslove u delu Loyalty program pod kojima možete ostvariti dodatne benefite kod naših Partnera.</p>
                        </li>
                        <h4>Reklamacija</h4>
                        <li>
                            <p>Platforma nije odgovorna prema Korisniku za valjano izvršenje Ugovora o kupoprodaji od strane Partnera. </p>
                        </li>
                        <li>
                            <p>Partner je dužan da izvrši svoje obaveze u skladu sa poslatim upitom, izvršenom rezervacijom ili kupovinom proizvoda, odnosno
                                Ugovorom o kupoprodaji, te ukoliko postoje bilo kakvi nedostaci u proizvodu, Korisnik može kontaktirati ili nas, koji postupamo
                                u ime Partnera, putem podrške Korisnicima na adresu elektronske pošte <a href="mailto:podrska@apoteka.rs">podrska@apoteka.rs</a>, ili direktno Partnera.</p>
                        </li>
                        <li>
                            <p>Partner je isključivo odgovoran za sve nedostatke u kvantitetu i kvalitetu isporučenog proizvoda, kao i za izvršenje drugih
                                obaveza iz Ugovora o kupoprodaji, te u slučaju da imate bilo kakvu reklamaciju da preuzeti ili isporučeni proizvod, molimo da pročitate i uslove za reklamaciju kod izabranog Partnera kod kojeg ste proizvod poručili.</p>
                        </li>
                        <h4>Brisanje i ukidanje Korisničkog naloga</h4>
                        <li>
                            <p>Korisnik ima pravo da obriše svoj nalog, u bilo kom trenutku i iz bilo kog razloga, korišćenjem opcije „obriši nalog". </p>
                        </li>
                        <li>
                            <p>Nakon brisanja naloga, Korisnik neće biti u mogućnosti da nastavi sa korišćenjem Platforme i njenih funkcionalnosti.</p>
                        </li>
                        <li>
                            <p>Platforma izričito zadržava pravo da deaktivira registrovane naloge za koje veruje da su generisani automatizovanim sredstvima
                                ili programerskim automatizacijama ili koje narušavaju ove Uslove korišćenja. Registrovani nalozi generisani softverskom
                                skriptom, botovima ili drugim automatizovanim sredstvima smatraće se nevažećim, odnosno biće deaktivirani.</p>
                        </li>
                        <li>
                            <p>Extra Care Group zadržava pravo da, u bilo kom momentu i bez prethodnog upozorenja, ukine ili onemogući pristup Platformi onom
                                Partneru ili Korisniku za kojeg utvrdi da krši ove Opšte uslove ili važeće propise ili čini postupke koji su na neki drugi način
                                štetni za Platformu ili druge naše Partnere i Korisnike u skladu sa svojom diskrecionom ocenom.</p>
                        </li>
                        <li>
                            <p>Nakon ukidanja naloga, Korisnik više neće moći da pristupi nalogu i takvom Korisniku je zabranjeno da ponovo koristi našu
                                Platformu.</p>
                        </li>
                        <li>
                            <p>U slučaju da smo Vam ukinuli Vaš Korisnički nalog, obavestićemo vas o navedenom, u slučaju da je obaveštenje moguće. </p>
                        </li>
                        <li>
                            <p>Ako smatrate da je do ukidanja naloga došlo usled greške, ili želite da sami obrišete nalog, a niste u mogućnosti, molimo Vas da
                                kontaktirate naš centar podrške putem adrese elektronske pošte <a href="mailto:podrska@apoteka.rs">podrska@apoteka.rs</a>.</p>
                        </li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>USLOVI KORIŠĆENJA ZA UDRUŽENJE I ČLANOVE UDRUŽENJA</li>
                    <ol type="1">
                        <li>
                            <p>Korišćenjem Platforme, članovi Udruženja dobijaju mogućnost umrežavanja, pristup dodatnim resursima, informacijama i podršci usmerenoj na poboljšanje zdravstvene zaštite i olakšavanje pristupa informacijama i željenim proizvodima. Udruženje tim putem želi da doprinese stvaranju boljih uslova za svoje članove, promovišući zdrave životne stilove i olakšavajući kako povezivanje članova, međusobno, ali i sa Partnerima Platforme, tako i pristup kvalitetnim zdravstvenim uslugama.</p>
                        </li>
                        <li>
                            <p>Udruženje i članovi Udruženja razumeju da Platforma samo obezbeđuje prostor u kome članovi Udruženja mogu da se povežu, komuniciraju i razmenjuju znanja, iskustva i jedni drugima pružaju pomoć i pruža mogućnost ostvarivanja benefita za članove kako to odredi Udruženje. Radi izbegavanja svake sumnje, Udruženje i članovi Udruženja prihvataju da Platforma ne može biti odgovorna za postupanje članova Udruženja na Platformi, niti za uredno ispunjenje obaveze Partnera iz Ugovora o kupoprodaji ili prilikom pružanja drugih Usluga ukoliko članovi Udruženja odluče da koriste i druge funkcionalnosti Platforme, a ne samo one namenjene članovima Udruženja, već da je Platforma jedino odgovorna za uredno funkcionisanje Platforme.</p>
                        </li>
                        <li>
                            <p>Udruženje je dužno da uputi svoje članove da se upoznaju sa ovim Opštim uslovima korišćenja i Politikom privatnosti Extra Care Group, kada koriste našu Platfrormu.</p>
                        </li>
                        <li>
                            <p>Na članove Udruženja koji se registruju na Platformu u cilju korišćenja benefita koje im Udruženje putem Platforme obezbeđuje, ali reše da koriste i druge funkcionalnosti Platforme, primenjuju se sve odredbe poglavlja VIII. ovih Uslova, koji se inače primenjuju na sve naše Korisnike.</p>
                        </li>
                        <li>
                            <p>Prava i obaveze Udruženja i Extra Care Group u vezi uslova korišćenja Platforme i njenih benefita koje Udruženje pruža svojim članovima preko Platforme su detaljno regulisani u posebnom ugovoru koji je važeći i obavezujući za obe ugovorne strane. Za sve što je drugačije regulisano u posebnom ugovoru u odnosu na ove Opšte uslove, primenjivaće se odredbe tog ugovora.</p>
                        </li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>PRAVA I OBAVEZE PARTNERA I KORISNIKA</li>
                    <ol type="1">
                        <li>
                            <p>Partneru i Korisniku se garantuje pravo korišćenja, što podrazumeva limitiranu, neisključivu, neprenosivu, besplatnu licencu za
                                preuzimanje i instaliranje, kao i pristup Platformi, isključivo za ličnu, nekomercijalnu upotrebu u skladu sa ovim Uslovima.</p>
                        </li>
                        <li>
                            <p>Prihvatanjem ovih Uslova, saglasni ste sa sledećim:</p>
                            <ul>
                                <li>
                                    <p>Zabranjena je svaka nezakonita, nedozvoljena, prevarna radnja ili radnja koja može dovesti nekog u zabludu;</p>
                                </li>
                                <li>
                                    <p>Nije dozvoljeno da vršite bilo koje radnje koje mogu dovesti do ometanja rada Platforme, drugih Partnera ili Korisnika
                                        Platforme, narušavanja rada, prikupljanja informacija sa Platforme na bilo koji način, uključujući i automatizaciju i
                                        slično;</p>
                                </li>
                                <li>
                                    <p>Izričito je zabranjeno da kupite, prodate, ustupite sa ili bez naknade, bilo koji deo vašeg naloga (uključujući i
                                        korisničko ime), tražite, prikupljate ili koristite kredencijale drugih korisnika za logovanje ili pokušate to da
                                        učinite;</p>
                                </li>
                                <li>
                                    <p>Strogo je zabranjeno: </p>
                                    <ul>
                                        <li>
                                            <p>bilo koja aktivnost koja je štetna, zabranjena pozitivno pravnim propisima, nepristojna na bilo koji način,
                                                uvredljiva, uznemiravajuća, proizvodi mržnju, ili nedopustiva na bilo koji način koji se može smatrati
                                                nedostojnim ili nepoželjnim;</p>
                                        </li>
                                        <li>
                                            <p>bilo koja aktivnost, čak i pokušaj, da na bilo koji način naudite, koristite u nenamenske svrhe, uznemiravate,
                                                hakujete, pretite ili zloupotrebite Platformu, bilo u celosti, bilo delimično, ili druge naše Partnere ili
                                                Korisnike;</p>
                                        </li>
                                        <li>
                                            <p>kreiranje i korišćenje višestrukih naloga sa jedne pristupne tačke (telefona/uređaja), neuobičajeno veliki broj
                                                prijava i odjava na servis sa jedne pristupne tačke a u cilju ometanja rada Platforme;</p>
                                        </li>
                                        <li>
                                            <p>da kreirate, koristite, činite dostupnim ili distribuirate bilo koji automatski softver, robot, bot, alate za
                                                ekstrahovanje ili drugi softver koji može na bilo koji način da utiče na ili komunicira sa Platformom na bilo
                                                koji način – uključujući i bez ograničenja bilo koje neautorizovane programe trećih lica koji presreću,
                                                imitiraju bilo koju komunikaciju sa Platformom, Partnerima ili Korisnicima, i/ili bilo koji neautorizovan
                                                program trećih lica koji prikuplja podatke o Platformi i čuva ih;</p>
                                        </li>
                                        <li>
                                            <p>bilo koja aktivnost koja podrazumeva interferenciju ili ometanje funkcionisanje Platforme, uključujući ubacivanje
                                                ili umetanje koda, „tunnelling", odbijanje servisa, modifikaciju softvera, korišćenje softvera koji imitira naš
                                                softver, emulaciju protokola, kreaciju i korišćenje privatnih servera ili bilo kojih sličnih servisa vezano za
                                                Platformu, uključujući i presretanje, prikupljanje podataka tzv. data mining aktivnostima korišćenjem
                                                neautorizovanih softvera i programa;</p>
                                        </li>
                                        <li>
                                            <p>pristup ili pokušaj pristupa delovima Platforme ili serverima koji nisu učinjeni javno dostupni sa naše
                                                strane;</p>
                                        </li>
                                        <li>
                                            <p>svaka aktivnost vezana za postavku bilo kojih fajlova koji sadrže maliciozni kod, viruse, „trojance", i slično,
                                                uključujući i bilo koje namerno oštećene fajlove ili druge fajlove koji sadrže maliciozni kod koji na bilo koji
                                                način mogu oštetiti ili ometati rad Platforme;</p>
                                        </li>
                                        <li>
                                            <p>bilo koja aktivnost koja može povrediti ili narušiti prava trećeg lica po pitanju intelektualne svojine, podataka
                                                o ličnosti, ugleda ili drugih prava;</p>
                                        </li>
                                        <li>
                                            <p>neovlašćeno oglašavanje, promotivne ili komercijalne komunikacije, obavljanje trgovačke delatnosti i/ili
                                                prodaje;</p>
                                        </li>
                                        <li>
                                            <p>prenos malicioznog ili neovlašćenog softvera;</p>
                                        </li>
                                        <li>
                                            <p>deljenje sa trećim licima svog korisničkog imena, lozinke, ili bilo kog pristupa kontrolnim informacijama koje
                                                obezbeđuju pristup ili korišćenje Platforme;</p>
                                        </li>
                                        <li>
                                            <p>bilo koja druga aktivnost koja može ometati ili prekinuti rad Platforme;</p>
                                        </li>
                                        <li>
                                            <p>prikupljanje i druga radnja obrade bilo kojih podataka o ličnosti koji se nalaze na Platformi;</p>
                                        </li>
                                        <li>
                                            <p>činjenje bilo koje slične radnje kao gore pomenute koja je zabranjena u skladu sa zakonskim propisima i drugim
                                                pravnim aktima.</p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <li>
                                <p>Nije dozvoljeno kršenje ovih Opštih uslova ili podsticanje trećih lica na kršenje ovih Opštih uslova, a ujedno vas
                                    savetujemo da sve povrede prijavite na adresu elektronske pošte našeg centra za podršku <a href="mailto:podrska@apoteka.rs">podrska@apoteka.rs</a>.</p>
                            </li>
                            <li>
                                <p>Možemo ukloniti sadržaj odnosno informacije sa Vašeg Korisničkog naloga ukoliko kršite odredbe ovih Opštih uslova ili drugih
                                    pravila i procedura koji su sastavni deo ovih Opštih uslova, ili ako je to u skladu sa odredbama pozitivnih propisa. </p>
                            </li>
                            <li>
                                <p>Sadržaj koji je obrisan, može na određeno vreme biti sačuvan radi naše arhive u skladu sa zakonskim propisima, nakon čega će
                                    biti trajno obrisan. Više o čuvanju i obradi podataka o ličnosti, možete naći u našoj Izjavi o privatnosti koja je dostupna
                                    na sledećem linku <a href="https://apoteka.rs/PrivacyPolicy">https://apoteka.rs/PrivacyPolicy.</a></p>
                            </li>
                            <li>
                                <p>Možemo odbiti da Vam pružamo pristup Platformi u celosti ili delimično, što uključuje i onemogućavanje ili ukidanje Vašeg
                                    korisničkog naloga, i to bez odlaganja, u cilju zaštite naše Platforme i njenih servisa, ako posumnjamo da povređujete tuđa
                                    prava i nastavljate sa povredom ili da zloupotrebljavate data prava u vezi sa korišćenjem Platforme, a naročito ukoliko
                                    imate nerazuman broj pretraga, veći broj loših ocena, koristite uređaj ili softver koji može naškoditi funkcionisanju
                                    Platforme, ili ukoliko činite bilo šta što dovodi do nepotrebnog opterećenja naše Platforme ili ukoliko povređujete odredbe
                                    ovih Opštih uslova i drugih pravila i procedura koji su sastavni deo ovih Opštih uslova, ili ako smo u obavezi da to učinimo
                                    u skladu sa odredbama pozitivnih propisa.</p>
                            </li>
                            <li>
                                <p>U cilju praćenja poštovanja odredbi ovih Opštih uslova, Partneri i Korisnici imaju pravo da ocene međusobnu saradnju, sa
                                    navođenjem razloga za datu ocenu, u roku od 30 dana od dana poslatog upita, rezervacije ili prodaje proizvoda, u zavisnosti
                                    od toga koji je datum kasniji. </p>
                            </li>
                            <li>
                                <p>Pozitivna ocena se dodeljuje za svaku uspešnu i efikasnu saradnju, dok se negativna ocena dodeljuje ovom Partneru ili
                                    Korisniku koji odustane od Ugovora o kupoprodaji na način suprotan ovim Opštim uslovima ili koji na bilo koji drugi način
                                    krši odredbe ovih Opštih uslova. Dodeljene ocene su vidljive, a zadržavamo diskreciono pravo poništavanja dodeljenih
                                    ocena.</p>
                            </li>
                            <li>
                                <p>Ovi Opšti uslovi ne daju nikakva prava trećim licima, a naši Partneri i Korisnici ne mogu nijedno svoje pravo niti obavezu
                                    propisanu ovim Opštim uslovima ustupiti, založiti, prodati, sublicencirati ili na drugi način preneti bilo kom trećem licu,
                                    organizaciji ili entitetu.</p>
                            </li>
                            <li>
                                <p>Zadržavamo sva prava, koja Vam nismo izričito garantovali ovim Opštim uslovima.</p>
                            </li>
                            <li>
                                <p>Mi možemo preneti naša prava i obaveze trećim licima, bez ikakvih ograničenja. Na primer, do ovoga može doći u slučaju
                                    promene kontrole ili po zakonu.</p>
                            </li>
                        </li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>INTELEKTUALNA SVOJINA</li>
                    <ol type="1">
                        <li>
                            <p>Extra Care Group ima isključiva autorska prava na svojoj Platformi i pratećim servisima i uslugama, što uključuje, ali se ne ograničava na baze
                                podataka, izvorni kod, tehničko okruženje u kojem je kod kreiran, uključujući sastavljanje koda, korišćenje spoljne biblioteke,
                                alate i sve ostale tehničke elemente, kao i druga prava intelektualne svojine na našoj Platformi kao što su sadržaj, dizajn,
                                žigovi i slično.</p>
                        </li>
                        <li>
                            <p>Partner i Korisnik ne stiču nikakva druga prava, osim onih koja su mi eksplicitno dodeljena ovim Opštim uslovima.</p>
                        </li>
                        <li>
                            <p>Iskorišćavanje pomenutih prava intelektualne svojine, bez naše izričite prethodne pisane saglasnosti smatraće se povredom tih
                                prava, te ćemo u tom slučaju biti ovlašćeni da iskoristimo sva dostupna pravna sredstva protiv Vas.</p>
                        </li>
                        <li>
                            <p>Nije vam dozvoljeno da kopirate, prikupljate, preuzimate, vršite reverzni inženjering, reprodukujete ili na drugi način koristite bilo koji sadržaj na našoj
                                Platformi u bilo koju svrhu osim one izričito propisane ovim Opštim uslovima, bez naše prethodne pisane saglasnosti.</p>
                        </li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>GARANCIJE</li>
                    <ol type="1">
                        <li>
                            <p>Iako Extra Care Group u svakom momentu nastoji i preduzima sve napore kako bi omogućio rad Platforme u skladu sa svim navedenim
                                funkcionalnostima, ne možemo da garantujemo da funkcionisanje Platforme neće biti prekinuto ili bez greške, da će nedostaci biti
                                otklonjeni odmah, kao i da ne postoji opasnost od virusa i drugih štetnih komponenti.</p>
                        </li>
                        <li>
                            <p>Partneri i Korisnici su upoznati sa činjenicom da Platforma može praviti greške, naročito kao posledica nepravilnog i/ili
                                nestručnog korišćenja, neispravnosti i/ili ograničenja u funkcionisanju operativnog sistema i/ili mrežne infrastrukture i/ili
                                računara Partnera ili Korisnika, u kojim slučajevima ne snosimo nikakvu odgovornost za nastalu štetu, ukoliko se kao takva šteta
                                utvrdi.</p>
                        </li>
                        <li>
                            <p>Korisnik je saglasan da možemo automatski izvršiti download i instalirati update aplikacija Platforme na njegov uređaj, što
                                podrazumeva i update u cilju popravke „bug"-ova i sl.</p>
                        </li>
                        <li>
                            <p>Zadržavamo pravo da povremeno onemogućimo, promenimo ili u potpunosti prestanemo, trajno ili privremeno, da pružamo usluge
                                Platforme ili bilo kog njenog dela, sa ili bez prethodnog obaveštenja. Dodatno, možemo prekinuti pristup delu ili celoj
                                Platformi Korisniku u svako doba, sa ili bez razloga, sa ili bez prethodnog obaveštavanja, sa trenutnim efektom, koje može
                                rezultirati oduzimanjem ili potpunim brisanjem svih informacija vezanih za Korisnički nalog na Platformi.</p>
                        </li>
                        <li>
                            <p>U slučaju da Platforma prekine sa radom, odnosno da se zabrani pristup Platformi u celini ili u jednom delu ili obriše Korisnički
                                nalog, Partner i Korisnik nemaju pravo na naknadu štete zbog nemogućnosti korišćenja Platforme.</p>
                        </li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>ODGOVORNOST</li>
                    <ol type="1">
                        <li>
                            <p>Extra Care Group ne snosi nikakvu odgovornost za nastale poremećaje u radu Platforme ili bilo kog njenog dela, ukoliko su ovi
                                poremećaji, gubitak ili oštećenja izazvani nestankom električne struje ili nestabilnim naponom u električnoj mreži, kvarom i
                                oštećenjem računara ili neke njegove komponente, preuzimanja, instalacije ili upotrebe softvera, usled nepravilnog rukovanja od
                                strane Partnera i/ili njegovog osoblja ili Korisnika, neovlašćenim ili nestručnim korišćenjem hardvera i aplikativnog softvera,
                                instalacijom, doinstalacijom, preinstalacijom ili uklanjanjem instalacije Platforme od strane Partnera i/ili njegovog osoblja
                                ili Korisnika, instalacijom i popravkama hardverske i mrežne opreme od strane nestručnih i neovlašćenih lica, kao i novim i
                                nepoznatim računarskim virusom ili upadom u sistem neovlašćenih osoba putem Interneta ili na drugi način.</p>
                        </li>
                        <li>
                            <p>Nismo odgovorni ni za gubitak profita, prihoda, informacija, podataka, slučajne, indirektne, direktne itd. štete koje proističu
                                ili su u vezi sa korišćenjem Platforme nastale okolnostima poput preuzimanja, instalacije ili upotrebe softvera, pa čak i ako
                                dođe do brisanja sadržaja, informacija ili profila na bilo koji način, uključujući bez ograničenja, gubitak ili odgovornost koja
                                nastaje ili proizilazi iz ili u vezi sa: a) sukobom softvera, u vezi sa softverom Platforme; b) povredom prava trećih lica od
                                strane naših Partnera ili Korisnika; c) neisporučivanjem podataka, pogrešnog isporučivanja podataka ili neautorizovanog pristupa
                                isporuci podataka; d) defektima ili virusima u softveru ili sadržaju, kao i distribuciji defekata ili virusa kroz softver ili
                                sadržaj; e) upotrebom ili zloupotrebom ličnog uređaja Korisnika ili softverskih aplikacija koje su na uređaju Partnera ili
                                Korisnika; ili f) nedostupnošću Platforme. </p>
                        </li>
                        <li>
                            <p>Extra Care Group neće snositi nikakvu odgovornost ni za kašnjenja i/ili neizvršavanja obaveza do kojih dođe usled više sile,
                                odnosno okolnosti koje su van njegove kontrole, uključujući, ali neograničavajući se na okolnosti poput rata, terorizma, pobuna,
                                embarga, civilnih i vojnih dejstava, požara, poplava, nesreća, štrajkova, nestašica transportnih objekata, goriva, energije,
                                radne snage i materijala, kao i usled drugih slučajeva više sile koje bi ugrozile funkcionisanje Platforme.</p>
                        </li>
                        <li>
                            <p>Extra Care Group će biti odgovoran isključivo za štetu koja proistekne iz korišćenja ili nemogućnosti korišćenja Platforme, a
                                koja je prouzrokovana njegovom krivicom ili krajnjom nepažnjom.</p>
                        </li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>IZMENE OPŠTIH USLOVA</li>
                    <ol type="1">
                        <li>
                            <p>Extra Care Group zadržava pravo da po sopstvenom nahođenju ažurira, dopunjava, menja i/ili prilagođava ove Opšte uslove. </p>
                        </li>
                        <li>
                            <p>Ukoliko dođe do promene ovih Opštih uslova, bićete blagovremeno obavešteni o nastalim promenama kroz obaveštenje koje ćemo Vam
                                poslati pre nego što takve izmene stupe na snagu. </p>
                        </li>
                    </ol>
                    <li className={legalDocumentStyles.Headers}>ZAVRŠNE ODREDBE</li>
                    <ol type="1">
                        <li>
                            <p>Za bilo kakve dodatne informacije, zahteve, sva pitanja i nedoumice u vezi sa korišćenjem Platforme Korisnik nam se može obratiti
                                slanjem elektronske pošte na adresu: <a href="mailto:podrska@apoteka.rs">podrska@apoteka.rs</a>.</p>
                        </li>
                        <li>
                            <p>U slučaju da se bilo koja odredba ovih Uslova od strane nadležnog organa proglasi nevažećom ili neprimenljivom, ista će se zameniti
                                važećom i primenljivom odredbom koja najviše odgovara, po smislu i svrsi, originalnoj odredbi, a ostatak odredbi Uslova će ostati u
                                potpunosti na snazi. Ako se neprimenljiva odredba ne može zameniti drugom odredbom, ista neće uticati na validnost i primenljivost
                                ostalih odredbi ovih Uslova.</p>
                        </li>
                        <li>
                            <p>U slučaju spora u vezi sa primenom ovih Uslova, sporovi će se rešavati sporazumno, a u slučaju neuspeha mirnog rešenja, biće konačno
                                rešeni pred stvarno nadležnim sudom u Beogradu, Srbija.</p>
                        </li>
                        <li>
                            <p>Ovi Opšti uslovi su sastavljeni na srpskom jeziku i biće tumačeni u skladu sa pravom Republike Srbije. Na sve što nije regulisano
                                ovim Opštim uslovima primenjivaće se relevantne odredbe važećih propisa Republike Srbije.</p>
                        </li>
                        <li>
                            <p>Ovi Opšti uslovi se objavljuju na internet stranici Extra Care Group <a
                                href="https://www.apoteka.rs/TermsOfService">https://www.apoteka.rs/TermsOfService</a> i počinju se primenjuju u roku od 8 (osam) dana od dana objavljivanja.</p>
                        </li>
                        <li>
                        <p>Poslednja verzija: 21.12.2024. godine. Prethodno važeću verziju od 29.08.2022. godine, možete pogledati na sledećem linku <a href="/m/TermsOfServicePrevious">ovde</a>.</p>
                        </li>
                    </ol>
                </ol>
                <p className={legalDocumentStyles.Headers}>OZNAČAVANJEM KUĆICE PORED OPŠTIH USLOVA KORIŠĆENJA POTVRĐUJETE DA STE ISTE PROČITALI I RAZUMELI SVE GORE POMENUTE ODREDBE I USLOVE I DA
                    IH PRIHVATATE, ČIME OVI OPŠTI USLOVI KORIŠĆENJA POSTAJU OBAVEZUJUĆI ZA VAS.</p>
            </div>
        </div>
    </Wrapper>
)

export default TermsOfService;